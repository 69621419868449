import { getFormProps, useForm } from '@conform-to/react';
import { parseWithZod } from '@conform-to/zod';
import { cssBundleHref } from '@remix-run/css-bundle';
import {
  json,
  type LoaderFunctionArgs,
  type LinksFunction,
  type MetaFunction,
  redirect,
  type ActionFunctionArgs } from
'@remix-run/node';
import {
  Form,
  Link,
  Links,
  LiveReload,
  Meta,
  NavLink,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useFetchers,
  useLoaderData,
  useLocation,
  useNavigation,
  useRouteLoaderData } from
'@remix-run/react';

import { useSpinDelay } from 'spin-delay';
import { z } from 'zod';
import { type IconName } from '@/icon-name';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { ErrorList } from './components/forms.tsx';
import { HasRole } from './components/has-role.tsx';
import { EpicToaster } from './components/toaster.tsx';
import { Avatar, AvatarFallback, AvatarImage } from './components/ui/avatar.tsx';
import { Button } from './components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger } from
'./components/ui/dropdown-menu.tsx';
import { Icon, href as iconsHref } from './components/ui/icon.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger } from
'./components/ui/tooltip.tsx';
import fontStyleSheetUrl from './styles/font.css';
import tailwindStyleSheetUrl from './styles/tailwind.css';
import { getAppState } from './utils/app-state.server.ts';
import { authenticator, getUserSession } from './utils/auth.server.ts';
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx';
import { DEFAULT_PATH } from './utils/constants.ts';
import { User } from './utils/db.server.ts';
import { DndProvider } from './utils/dnd-provider.tsx';
import { getEnv } from './utils/env.server.ts';
import { getSubscriptionIds } from './utils/feed.server.ts';
import {
  cn,
  combineHeaders,
  getDomainUrl,
  invariant,
  invariantResponse,
  isMobile } from
'./utils/misc.tsx';
import { useNonce } from './utils/nonce-provider.ts';
import { useHasRole } from './utils/permissions.ts';
import { useRequestInfo } from './utils/request-info.ts';
import { getRecentSearch } from './utils/search.server.ts';
import { getTheme, setTheme, type Theme } from './utils/theme.server.ts';
import { getToast } from './utils/toast.server.ts';

export const links: LinksFunction = () => {
  return [
  // Preload svg sprite as a resource to avoid render blocking
  { rel: 'preload', href: iconsHref, as: 'image' },
  // Preload CSS as a resource to avoid render blocking
  { rel: 'preload', href: fontStyleSheetUrl, as: 'style' },
  { rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
  cssBundleHref ? { rel: 'preload', href: cssBundleHref, as: 'style' } : null,
  { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
  {
    rel: 'alternate icon',
    type: 'image/png',
    href: '/favicons/favicon-32x32.png'
  },
  { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' }, (
  {
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  } as const), // necessary to make typescript happy
  //These should match the css preloads above to avoid css as render blocking resource
  { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
  { rel: 'stylesheet', href: fontStyleSheetUrl },
  { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
  { title: data ? 'Jazz Hub' : 'Error' },
  { name: 'description', content: `Jazz Analytics` }];

};

export async function loader({ request }: LoaderFunctionArgs) {
  const pathname = new URL(request.url).pathname;

  const session = await getUserSession(request);

  const [user, subscriptions, recentSearch, appState] = await Promise.all([
  session?.userId ? User.findByPk(session?.userId) : null,
  session?.userId ? getSubscriptionIds(session?.userId) : null,
  session?.userId ? getRecentSearch(session?.userId) : null,
  getAppState(request)]
  );

  if (!session && !user && pathname !== '/login') {
    console.info('user session has expired');
    await authenticator.logout(request, { redirectTo: '/login' });
  }

  if (session?.userId && !user) {
    console.info('something weird happened');
    // something weird happened... The user is authenticated but we can't find
    // them in the database. Maybe they were deleted? Let's log them out.
    await authenticator.logout(request, { redirectTo: '/login' });
  }

  // Admin users can reset a user's browser cache, local storage
  // and IndexedDB but setting this flag to true in the db
  if (user?.invalidate_cache) {
    // Reset flag to false so we don't get stuck in an infinte loop here
    await User.update(
      {
        invalidate_cache: false
      },
      {
        where: { email: user.email }
      }
    );

    return redirect(`/clear?redirectTo=${pathname}?fresh=${Date.now()}`);
  }

  if (user?.force_logout) {
    // Reset flag to false so we don't get stuck in an infinte loop here
    await User.update(
      {
        force_logout: false
      },
      {
        where: { email: user.email }
      }
    );

    return redirect(`/force-logout?redirectTo=${pathname}?fresh=${Date.now()}`);
  }

  if (pathname === '/') {
    if (
    ['analytics', 'scout', 'exec', 'health', 'coach'].includes(
      user?.role || ''
    ))
    {
      return redirect(DEFAULT_PATH);
    }
    return redirect('/team/40/overview');
  }

  const { toast, headers: toastHeaders } = await getToast(request);

  return json(
    {
      appState,
      subscriptions,
      recentSearch,
      user: user?.toJSON(),
      mobile: isMobile(request.headers.get('User-Agent')),
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: pathname,
        userPrefs: {
          theme: getTheme(request)
        }
      },
      ENV: getEnv(),
      toast
    },
    {
      headers: combineHeaders(toastHeaders)
    }
  );
}

export function useRootLoader() {
  const root = useRouteLoaderData<typeof loader>('root');
  invariant(root, 'root data is missing');
  return root;
}

const ThemeFormSchema = z.object({
  theme: z.enum(['system', 'light', 'dark'])
});

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const submission = parseWithZod(formData, {
    schema: ThemeFormSchema
  });

  invariantResponse(submission.status === 'success', 'Invalid theme received');

  const { theme } = submission.value;

  const responseInit = {
    headers: { 'set-cookie': setTheme(theme) }
  };
  return json({ result: submission.reply() }, responseInit);
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {}





}: {children: React.ReactNode;nonce: string;theme?: Theme;env?: Record<string, string>;}) {
  return (
    <html
      lang="en"
      className={`${theme} h-full overflow-x-hidden scroll-smooth`}>

			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=no" />

				<meta
          name="theme-color"
          media="(prefers-color-scheme: light)"
          content="#FFFFFF" />

				<meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="#0F172A" />

				<Links />
			</head>
			<body className="relative h-full bg-background text-foreground">
				<TooltipProvider>
					<DndProvider>{children}</DndProvider>
				</TooltipProvider>
				<script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`
          }} />

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<LiveReload nonce={nonce} />
			</body>
		</html>);

}

function App() {
  const location = useLocation();
  const data = useLoaderData<typeof loader>();
  const nonce = useNonce();
  const theme = useTheme();

  const hasPlayerDevelopmentPermission = false; // useHasRole(['analytics'])
  const hasSalaryCapPermission = useHasRole(['analytics', 'exec', 'scout']);
  const hasDraftPermission = useHasRole([
  'analytics',
  'scout',
  'exec',
  'health',
  'coach']
  );

  return (
    <Document nonce={nonce} theme={theme} env={data.ENV}>
			<RootPrefetchLinks />
			<div
        className={cn(
          'flex',
          data.user ? 'pb-[4.5625rem] md:pb-0 md:pl-[4.5625rem]' : 'h-full'
        )}>

				{data.user ?
        <>
						<nav className="fixed bottom-0 left-0 right-0 z-50 flex h-auto items-center border-t-[1px] border-muted bg-background p-4 md:right-auto md:top-0 md:h-full md:flex-col md:border-r-[1px] md:border-t-0">
							<Link
              className="rounded outline-ring"
              to={hasDraftPermission ? DEFAULT_PATH : '/team/40/overview'}>

								<Icon
                color="text-foreground"
                className="h-10 w-10"
                name="jazz-logo" />

							</Link>

							<ul className="flex flex-1 items-center justify-around md:mt-12 md:flex-col md:justify-normal md:space-y-2">
								<li>
									<NavLink className="rounded outline-ring" to="feed">
										{({ isActive, isPending }) =>
                  <Button
                    size="icon"
                    variant={isActive ? 'accent' : 'ghost'}
                    asChild>

												<span>
													<Icon
                        className={isPending ? 'animate-bounce' : ''}
                        size="lg"
                        name="newspaper" />

												</span>
											</Button>}

									</NavLink>
								</li>
								<li>
									<NavLink
                  className="rounded outline-ring"
                  to={hasDraftPermission ? DEFAULT_PATH : '/team/40/overview'}>

										{(props) => <NavigationLink icon="dribbble" {...props} />}
									</NavLink>
								</li>
								<li>
									<Tooltip>
										<TooltipContent side="right">
											<p className="text-sm font-medium">Performance Health</p>
											<p className="text-xs text-muted-foreground">
												Coming Soon
											</p>
										</TooltipContent>
										<TooltipTrigger>
											<Icon
                      className="m-1 opacity-50"
                      size="lg"
                      name="folder-heart" />

										</TooltipTrigger>
									</Tooltip>
								</li>
								{hasSalaryCapPermission ?
              <li>
										<NavLink
                  className="rounded outline-ring"
                  to="/salary/trade?team=40">

											{({ isActive, isPending }) =>
                  <Button
                    size="icon"
                    variant={isActive ? 'accent' : 'ghost'}
                    asChild>

													<span>
														<Icon
                        className={isPending ? 'animate-bounce' : ''}
                        size="lg"
                        name="dollar-sign" />

													</span>
												</Button>}

										</NavLink>
									</li> :

              <li>
										<Tooltip>
											<TooltipContent side="right">
												<p className="text-sm font-medium">Salary Cap</p>
												<p className="text-xs text-muted-foreground">
													Coming Soon
												</p>
											</TooltipContent>
											<TooltipTrigger>
												<Icon
                      className="m-1 opacity-50"
                      size="lg"
                      name="dollar-sign" />

											</TooltipTrigger>
										</Tooltip>
									</li>}

								{hasPlayerDevelopmentPermission ?
              <li>
										<NavLink className="rounded outline-ring" to="development">
											{({ isActive, isPending }) =>
                  <Button
                    size="icon"
                    variant={isActive ? 'accent' : 'ghost'}
                    asChild>

													<span>
														<Icon
                        className={isPending ? 'animate-bounce' : ''}
                        size="lg"
                        name="dumbbell" />

													</span>
												</Button>}

										</NavLink>
									</li> :
              null}
							</ul>

							<div className="hidden flex-1 lg:inline"></div>

							<DropdownMenu modal={false}>
								<DropdownMenuTrigger asChild>
									<Button
                  variant="ghost"
                  className="relative h-10 w-10 rounded-full">

										<Avatar className="h-10 w-10" data-private>
											<AvatarImage
                      src={data.user.image}
                      alt={data.user.username} />

											<AvatarFallback>{data.user.name}</AvatarFallback>
										</Avatar>
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent className="w-56" align="start">
									<DropdownMenuLabel className="font-normal">
										<div className="flex" data-private>
											<div className="flex flex-1 flex-col space-y-1">
												<p className="text-sm font-medium leading-none">
													{data.user.username}
												</p>
												<p className="text-xs leading-none text-muted-foreground">
													{data.user.email}
												</p>
											</div>
											<ThemeSwitch
                      userPreference={data.requestInfo.userPrefs.theme} />

										</div>
									</DropdownMenuLabel>
									<DropdownMenuSeparator />
									<HasRole roles={['analytics']}>
										<Form action="/admin/users" method="GET">
											<button className="w-full" type="submit">
												<DropdownMenuItem>Admin</DropdownMenuItem>
											</button>
										</Form>
									</HasRole>
									<HasRole roles={['analytics']}>
										<NavLink to={`/clear?redirectTo=${location.pathname}`}>
											<DropdownMenuItem>Clear Cache</DropdownMenuItem>
										</NavLink>
									</HasRole>
									<Form action="/whats-new" method="GET">
										<button className="w-full" type="submit">
											<DropdownMenuItem>What's New</DropdownMenuItem>
										</button>
									</Form>
									<Form action="/logout" method="POST">
										<button className="w-full" type="submit">
											<DropdownMenuItem>Logout</DropdownMenuItem>
										</button>
									</Form>
								</DropdownMenuContent>
							</DropdownMenu>
						</nav>
						<div className="mx-auto min-h-0 w-full min-w-0 max-w-[1655px] md:h-full md:flex-1">
							<Outlet />
						</div>
					</> :

        <div className="w-full">
						<Outlet />
					</div>}

			</div>
			<EpicToaster toast={data.toast} />
		</Document>);

}

export default App;

function RootPrefetchLinks() {
  return (
    <>
			<link
        rel="preload"
        as="fetch"
        href="/resources/all-search-results.json" />

		</>);

}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
  const hints = useHints();
  const requestInfo = useRequestInfo();
  const optimisticMode = useOptimisticThemeMode();
  if (optimisticMode) {
    return optimisticMode === 'system' ? hints.theme : optimisticMode;
  }
  return requestInfo.userPrefs.theme ?? hints.theme;
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
  const fetchers = useFetchers();
  const themeFetcher = fetchers.find((f) => f.formAction === '/');

  if (themeFetcher && themeFetcher.formData) {
    const submission = parseWithZod(themeFetcher.formData, {
      schema: ThemeFormSchema
    });

    if (submission.status === 'success') {
      return submission.value.theme;
    }
  }
}

function NavigationLink({
  icon,
  isActive,
  isPending




}: {icon: IconName;isActive: boolean;isPending: boolean;}) {
  const location = useLocation();
  const navigation = useNavigation();

  const isBBallRoute =
  ['/feed', '/health', '/salary', '/admin', '/cap', '/development'].filter(
    (route) => location.pathname.startsWith(route)
  ).length <= 0;

  const active = isActive || isBBallRoute;
  const pending = useSpinDelay(
    isPending || isBBallRoute && navigation.state !== 'idle',
    {
      delay: 300,
      minDuration: 400
    }
  );

  return (
    <Button size="icon" variant={active ? 'accent' : 'ghost'} asChild>
			<span>
				<Icon
          className={pending ? 'animate-bounce' : ''}
          size="lg"
          name={icon} />

			</span>
		</Button>);

}

function ThemeSwitch({ userPreference }: {userPreference?: Theme | null;}) {
  const fetcher = useFetcher<typeof action>();

  const [form] = useForm({
    id: 'theme-switch',
    lastResult: fetcher.data?.result
  });

  const optimisticMode = useOptimisticThemeMode();
  const mode = optimisticMode ?? userPreference ?? 'system';
  const nextMode =
  mode === 'system' ? 'light' : mode === 'light' ? 'dark' : 'system';
  const modeLabel = {
    light:
    <Icon name="sun">
				<span className="sr-only">Light</span>
			</Icon>,

    dark:
    <Icon name="moon">
				<span className="sr-only">Dark</span>
			</Icon>,

    system:
    <Icon name="laptop">
				<span className="sr-only">System</span>
			</Icon>

  };

  return (
    <fetcher.Form method="POST" {...getFormProps(form)} preventScrollReset>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex gap-2">
				<button
          name="intent"
          value="update-theme"
          type="submit"
          className="flex h-8 w-8 cursor-pointer items-center justify-center">

					{modeLabel[mode]}
				</button>
			</div>
			<ErrorList errors={form.errors} id={form.errorId} />
		</fetcher.Form>);

}

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce();

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>);

}